<template>
  <div id="bigScreen-parkingRecord">
    <h2>停车记录</h2>
    <el-table
        class="parkingRecord-table hideScrollBar"
        :data="tableData"
        :row-class-name="tableRowClassName" v-infinite-scroll>
      <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          width="100"
          align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="plateNum"
          label="车牌号"
          align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          label="停车状态" align="center" show-overflow-tooltip>
        <template #default="scope">
          <span class="parkingSpace">{{ scope.row.parkingStatus }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="parkingTime"
          label="时间" align="center" show-overflow-tooltip>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    refreshScroll(){
      this.$nextTick(()=>{
        let table = document.getElementsByClassName("parkingRecord-table")[0]
        let height = table.querySelector(".el-table__body-wrapper").offsetHeight

        table.scrollTo(0,height)
      })
    },
    tableRowClassName({row, rowIndex}) {
      if ((rowIndex + 1) % 2 === 0) {
        return 'bg';
      }
      return 'bg2';
    },
    async getList() {
      try {
        let res = await this.$http.post("/parking/record/list", {
          pageNum: 1,
          pageSize: 10,
        });
        if (res.code === 0) {
          // for (let item of res.data.list) {
          //   item.departureTime = item.departureTime ? this.$moment(item.departureTime).format(
          //       "yyyy-MM-DD HH:mm:ss"
          //   ) : "--";
          // }
          this.tableData = res.data.list;
          this.refreshScroll()
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  }
}
</script>

<style lang="scss">
#bigScreen-parkingRecord {
  height: calc(50% - 5.6rem);
  .el-table {
    width: 100%;
    height: calc(100% - 3.87rem);
    background-color: transparent;
    overflow-y: auto;
    &:before {
      background-color: transparent;
    }

    td.el-table__cell,
    th.el-table__cell.is-leaf {
      border-bottom: 0;
    }

    .cell {
      font-size: 0.88rem;
      color: white;
    }

    .parkingSpace {
      color: #fff557;;
    }

    tr {
      background-color: transparent;

      &:hover td.el-table__cell {
        background-color: initial;
      }
    }

    .bg, th.el-table__cell {
      background: rgba(101, 198, 231, 0.2);
    }

    .bg2 {
      background: rgba(101, 198, 231, 0.05);
    }
  }
}
</style>
